import { reactive, watch } from 'vue'
import isEqual from 'lodash/isEqual'
import {
  ClientInterface,
  TaskTypeItems,
  TaskSiteSettingsInterface,
  TaskInstructionInterface,
  NewClientInterface,
  DispatchTasksForm,
  LocationType,
  PayloadAssignment,
} from '../types'
import {
  DispatchTaskFormServiceState,
  DispatchTaskFormServiceType,
} from './types'
import { AuthModule } from '@tracktik/tt-authentication'
import { Attribute } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'
import {
  ADDRESS_WHITELISTED_VALUES,
  CLIENT_ITEMS_FIELDS,
  COORDINATES_WHITELISTED_VALUES,
  DEFAULT_WHITELISTED_VALUES,
  LOCATION_WHITELISTED_VALUE,
  TASK_TYPES_FIELDS,
} from './constants'

const removeNullUndefined = (
  obj: Record<Attribute<'dispatch-tasks'>, any>,
): Partial<Record<Attribute<'dispatch-tasks'>, any>> =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))

/**
 * This service is responsible for managing the state of the dispatch task form.
 * It fetches the necessary data from the API and provides methods to manipulate the form payload and initialModel.
 * Help to prefill some fields, and retrieve user form information to display.
 * Additionally, it offers methods to set and get the form state."
 * @param authModule - The authentication module.
 */

export const dispatchTaskFormService = (
  authModule: AuthModule,
): DispatchTaskFormServiceType => {
  const state: DispatchTaskFormServiceState = reactive({
    taskTypeItems: null as TaskTypeItems,
    clientItems: null as ClientInterface,
    taskSiteSettingsItems: null as TaskSiteSettingsInterface,
    newClient: null as NewClientInterface,
    clientId: 0,
    taskId: 0,
    isCustomLocation: false,
    isSavedLocation: false,
    errorsListToWhiteList: DEFAULT_WHITELISTED_VALUES as string[],
    isEditForm: true,
    report: null as Record<string, any>,
    hasToForceSave: true,
    assignment: null as
      | PayloadAssignment['user']
      | PayloadAssignment['group']
      | null,
  })

  const setClientId = (clientId: number): void => {
    state.clientId = clientId ?? 0
  }

  const setTaskId = (taskId: number): void => {
    state.taskId = taskId ?? 0
  }

  const setClientItems = (clientItems: ClientInterface): void => {
    state.clientItems = clientItems
  }
  const setTaskTypeItems = (taskTypeItems: TaskTypeItems): void => {
    state.taskTypeItems = taskTypeItems
  }
  const setTaskSiteSettingsItems = (
    taskSiteSettingsItems: TaskSiteSettingsInterface,
  ): void => {
    state.taskSiteSettingsItems = taskSiteSettingsItems
  }

  const setIsCustomLocation = (value: boolean): void => {
    state.isCustomLocation = value
  }

  const setIsSavedLocation = (value: boolean): void => {
    state.isSavedLocation = value
  }

  const setNewClient = (value: NewClientInterface): void => {
    state.newClient = value
  }

  const setIsEditForm = (formRootName: DispatchTasksForm): void => {
    state.isEditForm = formRootName === DispatchTasksForm.DISPATCH_TASKS_PUT
  }

  const setReportFormValues = (reportValues: Record<string, any>) => {
    if (!reportValues) {
      state.report = null
    } else {
      state.report = { ...reportValues }
    }
  }

  const setAssignment = (
    assignment: PayloadAssignment['user'] | PayloadAssignment['group'] | null,
  ) => {
    state.assignment = assignment
  }

  const setForceSave = (value: boolean) => {
    state.hasToForceSave = value
  }

  /**
   * Set the errors list based on the location type whitelist.
   * If it's an edit form, we aim to eliminate all errors related to the location since PUT requests don't allow location changes.
   * For location types 'coordinates,' errors related to the address are removed,
   * as they are required in the schema but not for this location type. Similarly, for location type 'address,'
   * errors related to coordinates are removed, as they are required in the schema but not for this location type.
   * Additionally, maintain default whitelisted values across all cases,
   * such as when the report schema requires an integer, but the API accepts a number or object.
   *
   * Why doesn't the API update the schema: Complex entity, high likelihood of introducing breaking changes.
   */
  const setErrorsToWhiteList = (value: LocationType) => {
    if (state.isEditForm) {
      state.errorsListToWhiteList = [
        ...DEFAULT_WHITELISTED_VALUES,
        LOCATION_WHITELISTED_VALUE,
      ]

      return
    }

    if (LocationType.COORDINATES === value) {
      state.errorsListToWhiteList = [
        ...DEFAULT_WHITELISTED_VALUES,
        ...COORDINATES_WHITELISTED_VALUES,
      ]

      return
    }

    if (LocationType.ADDRESS === value) {
      state.errorsListToWhiteList = [
        ...DEFAULT_WHITELISTED_VALUES,
        ...ADDRESS_WHITELISTED_VALUES,
      ]

      return
    }
  }

  const getErrorsListWhiteListed = (): string[] => state.errorsListToWhiteList

  const getIsEditForm = () => state.isEditForm

  const getClientId = (): number => {
    return state.clientId
  }

  const getTaskId = (): number => {
    return state.taskId
  }

  const getReportTemplateId = (): number | null => {
    return state.taskTypeItems?.reportTemplate || null
  }

  const getIsBillable = (): boolean => {
    return state.taskTypeItems?.billable || false
  }

  const getTaskTypePriority = (): TaskTypeItems['priority'] | null =>
    state.taskTypeItems?.priority || null

  const getTaskInstruction = (): TaskInstructionInterface | null => {
    const instruction = state.taskSiteSettingsItems?.taskInstructions?.filter(
      (taskInstruction) => taskInstruction.taskType === getTaskId(),
    )

    return instruction?.[0] || null
  }

  const getJobInstructions = (): string => {
    return getTaskInstruction()?.jobInstructions || ''
  }

  const getPriceTierId = (): number | null => {
    return getTaskInstruction()?.priceTier || null
  }

  const getDefaultInstructions = (): string => {
    return state.taskSiteSettingsItems?.defaultInstructions || ''
  }

  /**
   * Determines if the current task type is banned for the client.
   * This is based on the `taskSiteSettingsItems.bannedTaskTypes` array,
   * which lists all banned task types for the current site.
   *
   * @returns {boolean} - Returns `true` if the current task type is banned, otherwise `false`.
   */
  const getIsBannedTaskTypes = (): boolean =>
    state.taskSiteSettingsItems?.bannedTaskTypes?.includes(getTaskId()) || false

  const getIsDispatchBanned = (): boolean =>
    state.taskSiteSettingsItems?.dispatchBanned || false

  const getForceSave = (): boolean => state.hasToForceSave

  const getAssignment = ():
    | PayloadAssignment['user']
    | PayloadAssignment['group']
    | null => state.assignment

  const hasReportValues = (): boolean => !!state.report

  const initializeForm = (
    initialModel: Record<string, any>,
  ): Record<string, any> => {
    /**
     * If we have a location type, in the initial model,
     * we want to set the custom location flag to true and whitelist some errors
     * (please see `setErrorsToWhiteList` for more explanation).
     */
    if (
      initialModel?.locationType &&
      initialModel?.locationType !== LocationType.ACCOUNT_ADDRESS
    ) {
      setIsCustomLocation(true)
      setErrorsToWhiteList(initialModel.locationType)
    } else {
      setErrorsToWhiteList(LocationType.ACCOUNT_ADDRESS)
    }
    /**
     * We have to set the startDateTime to null to meet the schema condition.
     */
    if (state.isEditForm) {
      return { ...initialModel, startDateTime: null }
    }

    return { ...initialModel, startDateTime: null, report: {} }
  }

  const sanitizeFormModel = (
    formModel: Record<Attribute<'dispatch-tasks'>, any>,
  ) => {
    /**
     * We need to set deprecated fields to null, for example, startDateTime,
     * to meet the schema condition (if deprecated is null, use the other field).
     * However, the API validation doesn't permit a payload with these null values.
     */
    const sanitizedFormModel = removeNullUndefined(formModel)

    /**
     * If it's an edit form, we must exclude the location and locationType fields since the API validation
     * does not allow these fields in the payload for a PUT request.
     */
    if (getIsEditForm()) {
      const { location, locationType, ...modelWithoutLocation } =
        sanitizedFormModel

      return modelWithoutLocation
    } else {
      /**
       * Reports are added to the payload upon submission because schema validation only allows integers.
       */
      const report = { ...state.report, account: getClientId() }

      /**
       * We have the option to create a new client, but schema validation only allows integers for client property.
       * In this case, we add it to the payload during the submission phase
       */
      return state.isSavedLocation && state.isCustomLocation
        ? {
            ...sanitizedFormModel,
            report,
            client: state.newClient,
          }
        : {
            ...sanitizedFormModel,
            report,
          }
    }
  }

  const fetchTaskTypeItems = async (): Promise<void> => {
    const options = {
      fields: TASK_TYPES_FIELDS,
    }
    const response = await authModule
      .getApi()
      .get(Resources.TASK_TYPES, getTaskId(), options)

    setTaskTypeItems(response as TaskTypeItems)
  }

  const fetchClientItems = async (): Promise<void> => {
    const options = {
      fields: CLIENT_ITEMS_FIELDS,
    }

    const response = await authModule
      .getApi()
      .get(Resources.CLIENTS, getClientId(), options)

    setClientItems(response as ClientInterface)
  }

  const fetchTaskSiteSettingsItems = async (): Promise<void> => {
    const options = {
      include: ['taskInstructions', 'bannedTaskTypes', 'dispatchBanned'],
    }

    const response = await authModule
      .getApi()
      .get(Resources.TASK_SITE_SETTINGS, getClientId(), options)

    setTaskSiteSettingsItems(response as TaskSiteSettingsInterface)
  }

  // Store watcher references to stop them later
  const clientIdWatcher = watch(
    () => state.clientId,
    async (newValue, oldValue) => {
      if (!isEqual(newValue, oldValue) && newValue) {
        await Promise.all([fetchClientItems(), fetchTaskSiteSettingsItems()])
      } else {
        setClientItems(null as ClientInterface)
        setTaskSiteSettingsItems(null as TaskSiteSettingsInterface)
      }
    },
  )

  const taskIdWatcher = watch(
    () => state.taskId,
    async (newValue, oldValue) => {
      if (!isEqual(newValue, oldValue) && newValue) {
        await fetchTaskTypeItems()
      } else {
        setTaskTypeItems(null as TaskTypeItems)
      }
    },
  )

  const bannedTaskTypesWatcher = watch(
    () => getIsBannedTaskTypes(),
    (value) => {
      if (getIsEditForm()) {
        setForceSave(true)
      } else {
        setForceSave(!value)
      }
    },
  )

  const dispatchBannedWatcher = watch(
    () => getIsDispatchBanned(),
    (value) => {
      if (getIsEditForm()) {
        setForceSave(true)
      } else {
        setForceSave(!value)
      }
    },
  )

  const destroy = () => {
    clientIdWatcher()
    taskIdWatcher()
    bannedTaskTypesWatcher()
    dispatchBannedWatcher()
  }

  return {
    setClientId,
    setTaskId,
    getClientItems: () => state.clientItems,
    getTaskSiteSettingsItems: () => state.taskSiteSettingsItems,
    getIsCustomLocation: () => state.isCustomLocation,
    getIsSavedLocation: () => state.isSavedLocation,
    getTaskId: () => state.taskId,
    getTaskTypePriority,
    getClientId,
    getReportTemplateId,
    getIsBillable,
    getJobInstructions,
    getPriceTierId,
    getDefaultInstructions,
    setIsCustomLocation,
    setIsSavedLocation,
    setNewClient,
    setIsEditForm,
    getIsEditForm,
    initializeForm,
    getErrorsListWhiteListed,
    setErrorsToWhiteList,
    setReportFormValues,
    hasReportValues,
    sanitizeFormModel,
    getAssignment,
    setAssignment,
    getIsDispatchBanned,
    getIsBannedTaskTypes,
    getForceSave,
    setForceSave,
    destroy,
  }
}
