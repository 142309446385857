import Vue, { VueConstructor } from 'vue'
import { flatten } from 'flat'
import { unparse as unparseCSV } from 'papaparse'

import TracktikApiError from 'tracktik-sdk/lib/errors'
import { CancelableFunction } from 'tracktik-sdk/lib/common/entity-collection'

import { AuthModule, SessionRegion } from '@tracktik/tt-authentication'
import { DefinitionOptionMap, JSONSchema7 } from '@tracktik/tt-json-schema-form'
import { downloadAsFile } from '@tracktik/tt-helpers/lib/browser/downloadAsFile'

import { FormatManager } from '@/helpers/formats'

import { AclRule } from '@/tt-widget-sharable'
import { DashboardWidgetRowSizes } from '@/tt-widget-components/widgets/Dashboard/types'
import { widgetAllowsDownloadByType } from '@/tt-widget-components/components/allow-download-helpers'

import type { ResourceActionManagerInterface } from './services/resource-action/types'
import type { ResourceDataManagerInterface } from './services/resource-data/types'
import type {
  Attribute,
  ResourceMetaManagerInterface,
} from './services/resource-meta/types'
import { FieldTypes } from './services/resource-meta/types'
import { WidgetArchiveModel } from './WidgetArchiveModel'
import type { WidgetCategoryInterface } from './services/widget-collections/types'
import { getCommonSchemaDefinitions } from '@/tt-widget-components/lib/common-definitions'
import {
  WidgetCategoryModel,
  WidgetCategoryStoreModule,
} from './WidgetCategoryModel'
import { WidgetStoreModel, WidgetStoreModule } from './WidgetStoreModel'

import { PusherSdk } from '@tracktik/tt-pusher'
import cloneDeep from 'lodash/cloneDeep'
import uniqueId from 'lodash/uniqueId'

import {
  getWidgetConfigErrorMessages,
  isValidWidget,
} from '@/tt-widget-components/helpers/is-valid-widget'
import { UnsubscribeFunction } from '@/tt-event-manager'
import { ResourceUpdatedInterface } from '@/tt-widget-entity-flow'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'
import debounce from 'lodash/debounce'
import {
  CollectionQuery,
  FetchOptions,
  WidgetDownloadType,
  WidgetDownloadTypes,
} from '@/tt-widget-components/types'
import { EventManagerInterface } from '../tt-event-manager'
import { AttributeName, Filter } from '../tt-widget-components'
import { ContextAttributeMap } from '@/tt-widget-components/base/contextAttributeMap'
import { getDefaultFormat } from '@/tt-widget-components/helpers/getDefaultFormats'
import { FieldTypeTemporal } from '@/tt-entity-filter/temporal-filters/types'
import { DevConsole } from '@/plugins/DevConsole'

export interface WidgetOwner {
  id: string
  avatar: string
  name: string
}

export interface WidgetStoreModelMeta {
  id?: string
  uri?: string
  aclRule?: AclRule
  ownedBy?: WidgetOwner
  createdBy?: UserReference
  createdOn?: string
  bannerImage?: string
}

export interface WidgetStoreInterface {
  provider?: string
  title: string
  uid: string
  meta?: WidgetStoreModelMeta
  is: string
  category?: string
  ownedByMe?: boolean
  widget: WidgetReference
  isFromMarketplace?: boolean
}

/**
 * The JSON value of a widget
 */
export interface WidgetReference {
  is: string
  title: string
  description?: string
  tags?: string[]

  // @todo: to remove
  [k: string]: any

  meta?: {
    provider?: string
    lastAccessed?: string
    createdBy?: UserReference
    createdOn?: string
    updatedBy?: UserReference
    updatedOn?: string
    tags?: string[]
    aclRule?: AclRule
    ownedBy?: UserReference
  }
}

export interface WidgetSchema extends JSONSchema7 {
  type: 'object'
  required?: string[]
  title?: string
  description?: string
  properties: {
    is: {
      enum: string[]
    }
    title: JSONSchema7
    uid: JSONSchema7
    description: JSONSchema7
    [k: string]: any
  }
}

export interface WidgetTypeDefinition {
  name: string
  config?: WidgetConfig
  schema: WidgetSchema
  editorDefinitions?: DefinitionOptionMap
  component: {
    hook: HookConstructor
    template:
      | VueConstructor
      | (() => Promise<typeof import('*.vue') | VueConstructor<Vue>>)
  }
  ttcIgnoredAttrs?: string[]
}

export interface WidgetConfig {
  icon: string
  thumbnail: string
  color: string
  name?: string
  layout?: boolean
  description?: string
  component?: string
  editor?: WidgetEditorType
  print?: boolean
  pdf?: boolean
  excel?: boolean
  export?: boolean
}

export enum WidgetEditorType {
  LARGE = 'LARGE',
  INLINE = 'INLINE',
  DEFAULT = 'DEFAULT',
}

export interface WidgetManagerInterface {
  getWidgets(): WidgetTypeDefinition[]

  getHookClassName(name: string): HookConstructor

  registerWidget(widget: WidgetTypeDefinition): void

  getWidgetByName(name: string): WidgetTypeDefinition | undefined

  getTemplate(name: string): string

  getSchemaByName(name: string): JSONSchema7

  getTtcIgnoredAttributes(name: string): string[]
}

export type UserReference = {
  avatar?: string
  name?: string
  id?: number | string
}

export type WidgetLookup = WidgetReference

export type WidgetServices = {
  resourceDataManager: ResourceDataManagerInterface
  resourceMetaManager: ResourceMetaManagerInterface
  resourceActionManager: ResourceActionManagerInterface
  widgetManager: WidgetManagerInterface
  contextManager: ContextManagerInterface
}

export interface WidgetState {
  status?: WidgetStateStatus
  data?: Record<string, any>[] | null
  [k: string]: any
}

export enum WidgetStateStatus {
  PENDING = 'Pending',
  INITIALIZED = 'Initialized',
  INVALID = 'Invalid',
}

/**
 * IWidgetHook
 */
export interface WidgetHookInterface {
  state: WidgetState
  isValid: boolean
  hasDataSource: boolean
  errors: TracktikApiError[]
  hasErrors: boolean
  resource: string
  widget: WidgetReference
  supportsDownload: boolean
  canDownload: boolean
  downloadSupportedTypes?: WidgetDownloadTypes[]

  canDownloadFileType(type: WidgetDownloadTypes): boolean

  /**
   * Checks if the user has access to the widget
   */
  hasPermission(): boolean

  /**
   * Initialize
   */
  initialize(fetchData?: boolean): void

  /**
   * Destroy
   */
  destroy(): void

  /**
   * Queue the events received
   */
  deactivate(): void

  /**
   * Run received events while deactivated and reactivate event listeners
   */
  reactivate(): void

  /**
   * Return the list of resources used
   */
  getResourcesAndContext(): ResourceAndContextAttributeMap[]

  hasResource(resource: string): boolean
}

export interface ResourceAndContextAttributeMap {
  uid: string
  resource: string
  contextAttributeMap: ContextAttributeMap
}

export interface HookConstructorOptions {
  skipValidation?: boolean
}

export interface WidgetHookServices {
  authModule: AuthModule
  contextManager: ContextManagerInterface
  eventManager: EventManagerInterface
  resourceDataManager: ResourceDataManagerInterface
  resourceMetaManager: ResourceMetaManagerInterface
  widgetManager: WidgetManagerInterface

  /**
   * Pusher SDK used by the CollectionWidgetHook when `allowLiveUpdate` is `true`
   */
  pusherSdk?: PusherSdk
}

export interface WidgetHookDependencies {
  options?: HookConstructorOptions
  services: WidgetHookServices
  state?: WidgetState
  widget: WidgetReference
}

export type HookConstructor = {
  new (params: WidgetHookDependencies): WidgetHookInterface
}

export enum WidgetContainerType {
  DASHBOARD_TAB = 'DASHBOARD_TAB',
  DASHBOARD_CELL = 'DASHBOARD_CELL',
  STANDALONE = 'STANDALONE',
}

export type WidgetContainerInterface = {
  height?: string | number
  size?: DashboardWidgetRowSizes
  type?: WidgetContainerType
  hideTitle?: boolean
}

export type ContextFilterAppliedToWidget = {
  widgetType: string
  widgetTitle: string
  attributeFilters: string[]
}

export type ResourceFiltersMap = { [key: string]: Filter[] }
export type ResourceFilterLayer = { [layerName: string]: ResourceFiltersMap }

export type ContextDictionary = {
  modelContextFilters?: Filter[]
  filterLayers?: ResourceFilterLayer
  // @todo: to remove
  [k: string]: any
}

export type ContextManagerBaseInterface = EventManagerInterface

export interface ContextManagerInterface extends ContextManagerBaseInterface {
  readonly context: ContextDictionary
  readonly resources: string[]
  readonly contextFilterTypes: string[]

  registerWidget(widget: WidgetHookInterface | any): UnsubscribeFunction

  dispatchContextEvent(payload: any): void

  getContextFilters(): Filter[]

  getContextFilter(name: AttributeName): Filter | undefined

  removeContextFilter(attribute: string): void

  clearContextFilters(): void

  setContextFilter(filter: Filter): void

  setContextFilters(filters: Filter[]): void

  getResourceFilterLayer(layerName: string): ResourceFiltersMap | null

  setResourceFilterLayer(layerName: string, filterMap: ResourceFiltersMap)

  updateResourcesAndContextAttributeMap()

  getSelectedAccountIds(): string[]

  getSelectedRegions(): SessionRegion[]

  getSelectedRegionIds(): string[]

  getSelectedAccounts(): Record<string, unknown>[]

  hasAccessToSelectedAccounts(): boolean

  hasAccessToSelectedRegions(): boolean

  getSelectedDateRange(): Filter

  getFiltersCount(): number

  /**
   * Returns the most appropriate Temporal type for the date context filter settings.
   *
   * When the date context filter is mapped to different type of date fields,
   * we use the best type, aka the one with the most information needed.
   *
   * Order of priority :
   * 1- Date & time with timezone
   * 2- Date & time
   * 3- Date
   *
   * The ContextManager saves the date with the highest information needed.
   *
   * When applied to a "date" field that needs "less" information (no timezone and/or no time)
   * the unneeded part of the date filter will be removed by the QueryManager.
   */
  getDateRangeBestType(
    getAttributeMeta: (resource: string, attr: string) => Attribute,
  ): FieldTypeTemporal | undefined
}

/**
 * Error thrown when the CSV export has no data.
 */
export const ERR_CSV_EMPTY_DATA = 'ERR_CSV_EMPTY_DATA'

/**
 * A fallback hook
 */
export class BaseWidgetHook<WidgetModel extends WidgetReference>
  implements WidgetHookInterface
{
  errors: TracktikApiError[] = []
  objectId: string
  services: WidgetHookServices
  state: WidgetState
  unsubscribeFunctions: UnsubscribeFunction[] = []
  protected skipValidation = false

  private isActive = true
  private pendingFetchData: () => Promise<void> | null = null

  constructor({ options, services, state, widget }: WidgetHookDependencies) {
    // Keep a version of the hook
    this.objectId = uniqueId('Hook-')
    this.services = services
    this.state = Vue.observable({
      status: WidgetStateStatus.PENDING,
      widget: cloneDeep(widget) as WidgetModel,
      initialWidget: cloneDeep(widget),
      ...state,
    })
    this.skipValidation = options?.skipValidation ?? false
  }

  get canDownload(): boolean {
    return (
      this.supportsDownload &&
      this.downloadSupportedTypes.some((fileType: WidgetDownloadTypes) =>
        this.canDownloadFileType(fileType),
      )
    )
  }

  get supportsDownload(): boolean {
    return this.downloadSupportedTypes.length > 0
  }

  get hasDataSource(): boolean {
    return false
  }

  get hasErrors(): boolean {
    return this.errors.length > 0
  }

  get isInitialized(): boolean {
    return this.state.status === WidgetStateStatus.INITIALIZED
  }

  get isValid(): boolean {
    if (this.skipValidation) return true

    const isValid = isValidWidget(this.services.widgetManager, this.widget)

    if (!isValid) DevConsole.log(this.invalidConfigDetails)

    return isValid
  }

  get invalidConfigDetails(): Record<string, string> {
    return getWidgetConfigErrorMessages(
      this.services.widgetManager,
      this.widget,
    )
  }

  get resource(): string {
    return null
  }

  get title() {
    return this.state.widget.title
  }

  /**
   * @todo: refactor [FE-1379]
   */
  get downloadSupportedTypes(): WidgetDownloadTypes[] {
    const schema: JSONSchema7 = this.services.widgetManager.getSchemaByName(
      this.widget.is,
    )
    if (!schema?.properties?.allowDownload?.$ref) return []

    const matches = schema.properties.allowDownload.$ref.match(/\w+$/) ?? []
    const definitionName = matches[0]
    if (!definitionName) return []

    const definitionSchema: JSONSchema7 =
      getCommonSchemaDefinitions()[definitionName]
    if (!definitionSchema) return []

    const option: JSONSchema7 = definitionSchema.oneOf.find(
      (opt: JSONSchema7) => !!opt.properties,
    )
    const types: string[] = Object.keys(option.properties)
    const enumValues: WidgetDownloadTypes[] = []
    types.forEach((type: string): void => {
      const enumKey: string = Object.keys(WidgetDownloadType).find(
        (key: string): boolean => WidgetDownloadType[key] === type,
      )
      if (enumKey) {
        enumValues.push(WidgetDownloadType[enumKey])
      }
    })

    return enumValues
  }

  get widget(): WidgetModel {
    return this.state.widget
  }

  /**
   * You can overload this function to define your
   */
  set widget(widget: WidgetModel) {
    this.state.widget = widget
  }

  /**
   * Unique ID includes the hook number
   */
  get uniqueKey() {
    return (
      JSON.stringify({ ...this.state.widget, title: '', description: '' }) +
      '-' +
      this.objectId
    )
  }

  canDownloadFileType(type: WidgetDownloadTypes): boolean {
    if (!this.downloadSupportedTypes.includes(type)) return false

    return widgetAllowsDownloadByType(this.widget, type)
  }

  /**
   * Unsubscribe
   */
  destroy(): void {
    this.unsubscribe()
  }

  getResourcesAndContext(): ResourceAndContextAttributeMap[] {
    return []
  }

  hasResource(resource: string): boolean {
    return this.getResourcesAndContext().some(
      (obj) => obj.resource === resource,
    )
  }

  /**
   * Return false by default. Override this
   */
  hasPermission(): boolean {
    return false
  }

  protected async fetchData(fetchOptions?: FetchOptions): Promise<void> {
    console.log(`"fetchData" not implemented for ${this.objectId}`)
  }

  /**
   * Subscribe the widget
   */
  initialize(): void {
    const debouncer = debounce((callback) => callback(), 200)

    const fetchData = () => this.fetchData()
    const fetchDataNoCache = () => this.fetchData({ disableCache: true })

    this.on(
      EntityIntentTypes.RESOURCE_UPDATED,
      (payload: ResourceUpdatedInterface) => {
        if (this.hasResource(payload.resource)) {
          if (this.isActive) debouncer(fetchDataNoCache)
          else this.pendingFetchData = fetchDataNoCache
        }
      },
    )

    this.on(EntityIntentTypes.CONTEXT_CHANGE, () => {
      if (this.isActive) debouncer(fetchData)
      else this.pendingFetchData = this.pendingFetchData ?? fetchData
    })

    // Register the widget
    this.unsubscribeFunctions.push(
      this.services.contextManager.registerWidget(this),
    )
  }

  /**
   * Register some events
   */
  protected on(eventName: string, callback) {
    const unsubscribeFn = this.services.contextManager.subscribeEvent(
      eventName,
      callback,
    )

    this.unsubscribeFunctions.push(unsubscribeFn)
  }

  setAsInvalidState() {
    this.state.status = WidgetStateStatus.INVALID
  }

  /**
   * Widgets initialized
   */
  setInitiated() {
    this.state.status = WidgetStateStatus.INITIALIZED
  }

  setPending() {
    this.state.status = WidgetStateStatus.PENDING
  }

  unsubscribe(): void {
    this.unsubscribeFunctions.forEach((fx: UnsubscribeFunction) => {
      fx()
    })
  }

  deactivate() {
    this.isActive = false
  }

  async reactivate() {
    this.isActive = true

    if (this.pendingFetchData) {
      await this.pendingFetchData()
      this.pendingFetchData = null
    }
  }

  protected getAttributeType(attribute: string): string {
    const attrMeta = this.services.resourceMetaManager.getAttribute(
      this.resource,
      attribute,
    )

    return attrMeta?.type ?? FieldTypes.Label
  }

  // @TODO: add tests
  private prepareItemsForCSV(
    items: Record<string, unknown>[],
    columns: string[],
  ): string {
    const formatCellValue = ([key, value]) => {
      const formatName = getDefaultFormat(this.getAttributeType(key))

      const hasFormatter = formatName && FormatManager.hasFormat(formatName)

      const formatValue = () =>
        FormatManager.parse(
          formatName,
          value,
          this.services.authModule.getUserPreferences(),
        )

      const formattedValue = hasFormatter ? formatValue() : value

      return [key, formattedValue]
    }

    const formatRow = (row) =>
      flatten(Object.fromEntries(Object.entries(row).map(formatCellValue)), {
        safe: true,
      })

    const formattedItems = items.map(formatRow)

    return unparseCSV(formattedItems, { columns })
  }

  /**
   * Returns an object that can be used to export the data to CSV.
   */
  protected createCsvExporter(collectionQuery: CollectionQuery): {
    cancel: CancelableFunction
    run: () => Promise<void>
  } {
    /**
     * Create a cancelable fetch method.
     */
    const csvExporter =
      this.services.resourceDataManager.cancelableGetCollection(
        collectionQuery,
        { disableCache: true },
      )

    /**
     * Wrap the run method to handle the response and download the file.
     */
    const run = async (): Promise<void> => {
      const resp = await csvExporter.run()

      if (!resp.items?.length) throw new Error(ERR_CSV_EMPTY_DATA)

      const fieldNames = collectionQuery.fields.map(
        ({ attribute }) => attribute,
      )
      const extensionNames = collectionQuery.extension
        ? [...collectionQuery.extension]
        : []

      const columns = [...fieldNames, ...extensionNames]

      const csvString = this.prepareItemsForCSV(resp.items, columns)

      downloadAsFile(csvString, this.title, 'CSV')
    }

    return { cancel: csvExporter.cancel, run }
  }
}

export {
  ResourceDataManagerInterface,
  ResourceActionManagerInterface,
  ResourceMetaManagerInterface,
  WidgetCategoryInterface,
  WidgetArchiveModel,
  WidgetStoreModel,
  WidgetStoreModule,
  WidgetCategoryModel,
  WidgetCategoryStoreModule,
}
