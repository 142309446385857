<template>
  <div
    class="operators--container flex--column"
    :class="editMode && 'operators--edit-mode'"
  >
    <ResourceAllowedOperations
      v-slot="{ allowsActions: appAllowsActions }"
      :resource-name="operationCenterResource"
      class="flex--column"
    >
      <div v-if="appAllowsActions" class="flex--row align-center pa-3">
        <v-expand-transition>
          <span
            v-if="editMode"
            class="caption green--text"
            v-text="$t('tt-entity-design.operation-centers.changes-saved')"
          />
        </v-expand-transition>

        <v-spacer />

        <div class="d-flex align-center">
          <span
            class="overline font-weight-light"
            v-text="$t('common.edit.btn')"
          />

          <v-switch
            v-model="editMode"
            :disabled="!canAddOperator && !canRemoveOperator"
            color="ttPrimary"
            hide-details
            class="pt-0 mt-0 pl-3 overline"
          />
        </div>
      </div>

      <v-expand-transition>
        <div v-if="editMode" class="d-flex flex-column level0">
          <div class="flex--row align-center pa-3">
            <EntitySelectorField
              v-model="newEmployeeId"
              :resource="selectorResource"
              class="w-100"
              :query-options="addOperatorQueryOptions"
              :disabled="!canAddOperator"
              rounded
              :label="$t('tt-entity-design.operation-centers.search-employee')"
              hide-details
            />

            <v-btn
              large
              icon
              color="success"
              class="add--button mx-1"
              elevation="0"
              :disabled="!newEmployeeId || !canAddOperator"
              :loading="isLoading"
              @click="addOperator"
            >
              <v-icon> mdi-account-plus </v-icon>
            </v-btn>
          </div>
        </div>
      </v-expand-transition>

      <v-divider />

      <WidgetFactory
        class="h-100"
        :widget="widget"
        skip-validation
        @update:hook="hook = $event"
      />
    </ResourceAllowedOperations>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { CollectionQuery, ListWidgetModel } from '@/tt-widget-components/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  ActionProperty,
  Action,
  Attribute,
  CustomFilter,
} from '@/tt-entity-design/src/schema-types'

import { Resources } from '@/tt-entity-design/src/types'
import OperationCenterOperatorTile from './OperationCenterOperatorTile.vue'
import { updateDOM } from '@/helpers/dom/updateDOM'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

const { OPERATION_CENTERS } = Resources
const OPERATORS: Attribute<typeof OPERATION_CENTERS> = 'operators'
const ADD_OPERATOR: Action<typeof OPERATION_CENTERS> = 'add-operator'
const REMOVE_OPERATOR: Action<typeof OPERATION_CENTERS> = 'remove-operator'

const OPERATOR_RESOURCE = Resources.EMPLOYEES

type AddActionProperty = ActionProperty<
  typeof OPERATION_CENTERS,
  typeof ADD_OPERATOR
>

type DeleteProperty = ActionProperty<
  typeof OPERATION_CENTERS,
  typeof REMOVE_OPERATOR
>

const EMPLOYEE_NOT_IN_OP_CENTER_FILTER: CustomFilter<'employees'> =
  'excludeAlreadyAssignedToOperationCenter'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersEmployees',
  inject: ['getItemHook'],
  data: () => ({
    hook: null as ListWidgetHook | null,
    isLoading: false,
    editMode: false,
    newEmployeeId: null,
    //save employee to refresh entity selector query
    addedEmployees: [],
  }),
  computed: {
    selectorResource(): string {
      return OPERATOR_RESOURCE
    },
    operationCenterResource(): string {
      return OPERATION_CENTERS
    },
    canAddOperator(): boolean {
      return this.getItemHook().isActionAvailable(ADD_OPERATOR)
    },
    canRemoveOperator(): boolean {
      return this.getItemHook().isActionAvailable(REMOVE_OPERATOR)
    },
    addOperatorQueryOptions(): Partial<CollectionQuery> {
      return {
        returnCount: false,
        customFilters: [
          {
            filterName: EMPLOYEE_NOT_IN_OP_CENTER_FILTER,
            value: this.operationCenterId,
          },
        ],
        filters: [
          {
            value: this.addedEmployees,
            operator: FilterOperatorType.NOT,
            attribute: 'id',
          },
        ],
      }
    },
    operationCenterId(): number {
      return this.getItemHook().getEntityId()
    },
    widget(): ListWidgetModel {
      const orderAttrName: Attribute<typeof OPERATOR_RESOURCE> = 'firstName'
      const orderAttrLastName: Attribute<typeof OPERATOR_RESOURCE> = 'lastName'

      return {
        is: 'ListWidget',
        title: '',
        disableResourcePreviewOnClick: true,
        allowActions: true,
        listItem: {
          // @ts-ignore
          is: OperationCenterOperatorTile,
          props: {
            // @ts-ignore
            showDeleteButton: () => this.editMode,
            // @ts-ignore
            onDelete: (id) => this.deleteOperator(id),
            // @ts-ignore
            canDelete: () => this.canRemoveOperator,
          },
        },
        query: {
          resource: OPERATOR_RESOURCE,
          relationListResource: {
            resource: OPERATION_CENTERS,
            id: this.operationCenterId,
            attribute: OPERATORS,
          },
          sort: [
            {
              attribute: orderAttrName,
              direction: 'ASC',
            },
            {
              attribute: orderAttrLastName,
              direction: 'ASC',
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
  methods: {
    async addOperator() {
      this.isLoading = true
      await updateDOM()

      const payload: Record<AddActionProperty, number> = {
        operator: this.newEmployeeId,
      }

      await this.$auth
        .getApi()
        .doAction(
          OPERATION_CENTERS,
          this.operationCenterId,
          ADD_OPERATOR,
          payload,
        )

      await this.afterUpdate()

      this.addedEmployees.push(this.newEmployeeId)
      this.newEmployeeId = null
    },
    async deleteOperator(employeeId: number) {
      await updateDOM()

      const payload: Record<DeleteProperty, number> = {
        operator: employeeId,
      }

      await this.$auth
        .getApi()
        .doAction(
          OPERATION_CENTERS,
          this.operationCenterId,
          REMOVE_OPERATOR,
          payload,
        )
      this.addedEmployees = this.addedEmployees.filter(
        (id) => id !== employeeId,
      )
      await this.afterUpdate()
    },
    async afterUpdate() {
      const updateOperatorList = () => this.hook?.update({ disableCache: true })
      const updateOperationCenterList = () =>
        this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
          resource: OPERATION_CENTERS,
        })

      await Promise.all([updateOperatorList(), updateOperationCenterList()])
      this.isLoading = false
    },
  },
})
</script>

<style scoped>
.operators--container.operators--edit-mode {
  border-left: 1px solid var(--v-ttPrimary-base);
}
</style>
