import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'

import {
  BatchFile,
  BatchFileActions,
  BatchFileOnFailureOptions,
  BatchFileOperation,
  BatchFileResponse,
} from '@/types'
import { EntityPersistRunner } from '@/tt-widget-entity-flow/EntityPersistRunner'
import {
  FilterOperatorType,
  ResourceDataManagerInterface,
} from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { rootExceptionTypeId } from '@/tt-entity-design/src/components/exception-types/exception-types-parent'

import SchemaHelper from './schema-helper'
import {
  SchedulingEntityResource,
  SchedulingEntity,
  SchedulingExceptionTypeEntity,
  rangeTimeKeys,
  ExceptionData,
  SchedulingGroupEntity,
  ExceptionType,
  SchedulingGroupBatch,
  SchedulingEntityKeys,
  SchedulingExceptionType,
  SchedulingGroupType,
} from '../types'
import { createSiteTaskScheduleTimingsHelper } from '@/tt-widget-views/site-tasks/helpers/site-task-schedule-timings'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'

function parseExceptionDays(exceptionDays: {
  items: ExceptionType[]
}): ExceptionType[] {
  const grouped = groupBy(exceptionDays.items, 'parent')

  const rootTypes = grouped['null'] ?? []

  //Get all the root types for the holidays with their sub types
  return sortBy(
    rootTypes
      .map((exceptionRootType) => ({
        ...exceptionRootType,
        children: grouped[exceptionRootType.id],
      }))
      .filter((exceptionRootType) => !isEmpty(exceptionRootType.children)),
    [
      (exception) => {
        //Order the exception types so that Holiday and Holiday eves are together
        switch (exception.id) {
          case rootExceptionTypeId.Closed:
            return 1
          case rootExceptionTypeId.Holiday:
            return 2
          case rootExceptionTypeId.HolidayEve:
            return 3
          default:
            return 4
        }
      },
    ],
  )
}

/**
 * Helper class to interact with "scheduling" resources.
 *
 * eg: Mobile Schedules, Mobile Runsheets, Site Task Schedules
 * @TODO: SEU-2090: Finish Site Task Schedules
 */
export default class SchedulingGroupApiHelper {
  private resource: SchedulingEntityResource
  private schemaHelper: SchemaHelper

  constructor(resource: SchedulingEntityResource) {
    this.resource = resource
    this.schemaHelper = new SchemaHelper(resource)
  }

  async fetchAccountExceptions(
    accountId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<ExceptionType[]> {
    const filterMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_SCHEDULES]: 'forScheduleByAccountId',
      [Resources.MOBILE_RUNSHEETS]: 'forRunsheetByAccountId',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'forSiteTaskByAccountId',
    }
    const filters = [
      {
        attribute: filterMap[this.resource],
        operator: FilterOperatorType.EQUAL,
        value: accountId,
      },
      //Only add the filter for the Closed days if the resource is Runsheets
      ...(this.resource === Resources.MOBILE_RUNSHEETS
        ? [
            {
              attribute: 'excludeTreeById',
              operator: FilterOperatorType.EQUAL,
              value: rootExceptionTypeId.Closed,
            },
          ]
        : []),
    ]
    const response = await resourceDataManager.getCollection(
      {
        resource: Resources.EXCEPTION_TYPES,
        filters,
        limit: TTC_API_MAX_LIMIT,
      },
      { disableCache: true },
    )

    return parseExceptionDays(response)
  }

  fetchSchedulingExceptions = async (
    groupId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<SchedulingExceptionTypeEntity[]> => {
    // Exception type isn't included in the response by default
    const fieldsNames: (keyof SchedulingExceptionTypeEntity)[] = [
      'id',
      'exceptionType',
      'perform',
      'timeFrom',
      'timeTo',
    ]
    const fields = fieldsNames.map((attribute) => ({ attribute }))
    const filterMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_RUNSHEETS]: 'runsheet.runsheetGroup',
      [Resources.MOBILE_SCHEDULES]: 'mobileSchedule.scheduleGroup',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'schedule',
    }
    const filters = [
      {
        attribute: filterMap[this.resource],
        operator: FilterOperatorType.EQUAL,
        value: groupId,
      },
    ]
    const response = await resourceDataManager.getCollection(
      {
        resource: SchedulingExceptionType[this.resource],
        fields,
        filters,
        limit: TTC_API_MAX_LIMIT,
      },
      { disableCache: true },
    )

    /**
     * Group exceptions by type to get rid of "duplicates" of the same type
     */
    const exceptionsByType = groupBy(
      response.items as SchedulingExceptionTypeEntity[],
      'exceptionType',
    )

    return Object.values(exceptionsByType).map((exceptions) => exceptions[0])
  }

  async fetchSchedulingEntityByGroup(
    groupId: number,
    {
      resourceDataManager,
    }: { resourceDataManager: ResourceDataManagerInterface },
  ): Promise<SchedulingEntity[]> {
    const filters = [
      {
        attribute: this.getGroupAttribute(),
        operator: FilterOperatorType.EQUAL,
        value: groupId,
      },
    ]
    const response = await resourceDataManager.getCollection(
      { resource: this.resource, filters, limit: TTC_API_MAX_LIMIT },
      { disableCache: true },
    )

    return response.items as SchedulingEntity[]
  }

  async saveSchedulingExceptionsSchedule(
    {
      data,
      schedulingItemIds,
      accountId,
    }: {
      data: SchedulingGroupBatch
      schedulingItemIds: number[]
      accountId: number
    },
    {
      persister,
      resourceDataManager,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse> {
    const keyMap: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_SCHEDULES]: 'scheduleGroup',
      [Resources.MOBILE_RUNSHEETS]: 'runsheetGroup',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'schedule', // to review
    }

    const getGroupEntityID = (): number => {
      const groupAttribute = keyMap[this.resource]

      return data[groupAttribute] as number
    }

    const [allHolidays, oldExceptions] = await Promise.all([
      this.fetchAccountExceptions(accountId, {
        resourceDataManager,
      }),
      this.fetchSchedulingExceptions(getGroupEntityID(), {
        resourceDataManager,
      }),
    ])

    const newExceptionsData = data.holidays ?? {}
    const newExceptionsDataEntries = Object.entries(newExceptionsData)
    const shouldRemoveException = (exception: SchedulingExceptionTypeEntity) =>
      newExceptionsDataEntries.every(
        ([exceptionType, data]) =>
          exception.exceptionType !==
            this.schemaHelper.parseHolidayKey(exceptionType) || isEmpty(data),
      )
    const exceptionsToRemoveIds = oldExceptions
      .filter(shouldRemoveException)
      .map(({ exceptionType }) => exceptionType)

    if (this.resource === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
      return createSiteTaskScheduleTimingsHelper(
        persister.authModule,
      ).saveSiteTaskScheduleExceptions(
        getGroupEntityID(),
        newExceptionsData,
        exceptionsToRemoveIds,
      )
    } else {
      const exceptionsPayload = this.buildExceptionTypes({
        allHolidays,
        holidayData: newExceptionsData,
        removedHolidayIds: exceptionsToRemoveIds,
        groupId: getGroupEntityID(),
        schedulingItemIds,
      })

      return persister.executeBatchFileRequest(exceptionsPayload)
    }
  }

  private getDayOfWeekAttribute(): string {
    return SchedulingEntityKeys[this.resource].dayOfWeekStart
  }

  private getGroupAttribute(): string {
    return SchedulingEntityKeys[this.resource].groupName
  }

  async saveItemsSchedules(
    data: SchedulingGroupBatch,
    {
      resourceDataManager,
      persister,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse> {
    /**
     * review and strongly type SchedulingGroupBatch
     */
    const groupId = data[this.getGroupAttribute()] as number

    /**
     * Site Task Schedule Timings does not have an "archive" action but a delete operation.
     * We need to handle this case separately.
     * @TODO: to review
     */
    if (this.resource === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
      const payload = this.schemaHelper.getDaysData(data.days)

      return createSiteTaskScheduleTimingsHelper(
        persister.authModule,
      ).saveSiteTaskScheduleTimings(groupId, payload)
    } else {
      const oldItems = await this.fetchSchedulingEntityByGroup(groupId, {
        resourceDataManager,
      })

      const newItemDataEntries = Object.entries(data.days)

      const shouldArchiveItem = (entity: SchedulingEntity) =>
        newItemDataEntries.every(
          ([dayOfWeek, data]) =>
            entity[this.getDayOfWeekAttribute()] !== dayOfWeek || isEmpty(data),
        )

      const itemsToArchiveIds = oldItems
        .filter(shouldArchiveItem)
        .map(({ id }) => id)

      const batchOperation = this.schemaHelper.createBatchRequest(
        data,
        itemsToArchiveIds,
      )

      return persister.executeBatchFileRequest(batchOperation)
    }
  }

  private buildExceptionTypes({
    allHolidays,
    schedulingItemIds, // IDs of the "timing" resource
    groupId,
    holidayData,
    removedHolidayIds = [],
  }: {
    allHolidays: ExceptionType[]
    schedulingItemIds: number[]
    groupId: number
    holidayData: Record<string, ExceptionData>
    removedHolidayIds?: number[]
  }): BatchFile {
    const children = allHolidays.map((holiday) => holiday.children).flat()

    const addOperations: BatchFileOperation[] = children.reduce(
      (holidayOperations, holiday) => {
        const data =
          holidayData[this.schemaHelper.getHolidayKey(holiday.id)] ||
          holidayData[this.schemaHelper.getHolidayKey(holiday.parent)]
        //Skip if the parent holiday is not set, and the holiday is not selected
        if (!data) {
          return holidayOperations
        }

        return [
          ...holidayOperations,
          {
            action: BatchFileActions.EXECUTE,
            actionName: 'add-exception-types',
            resource: SchedulingGroupType[this.resource],
            lookup: groupId,
            data: {
              [rangeTimeKeys.DO_NOT_PERFORM]:
                data[rangeTimeKeys.DO_NOT_PERFORM],
              exceptionTypes: [holiday.id],
              ...(!data[rangeTimeKeys.DO_NOT_PERFORM]
                ? {
                    [rangeTimeKeys.START_TIME_KEY]:
                      data[rangeTimeKeys.START_TIME_KEY],
                    [rangeTimeKeys.END_TIME_KEY]:
                      data[rangeTimeKeys.END_TIME_KEY],
                  }
                : {}),
            },
          },
        ]
      },
      [],
    )

    const lookupKeys: Record<SchedulingEntityResource, string> = {
      [Resources.MOBILE_RUNSHEETS]: 'runsheet',
      [Resources.MOBILE_SCHEDULES]: 'mobileSchedule',
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: 'schedule',
    }

    const removeOperations: BatchFileOperation[] = removedHolidayIds
      .map((exceptionTypeId) =>
        schedulingItemIds.map((schedulingItemId) => ({
          resource: SchedulingExceptionType[this.resource],
          data: {},
          action: BatchFileActions.EXECUTE,
          actionName: 'remove',
          lookup: {
            [lookupKeys[this.resource]]: schedulingItemId,
            exceptionType: exceptionTypeId,
          },
        })),
      )
      .flat()

    return {
      onFailure: BatchFileOnFailureOptions.ROLLBACK,
      operations: [...addOperations, ...removeOperations],
    }
  }

  async resyncSchedulingItemFromGroupData(
    group: SchedulingGroupEntity,
    {
      persister,
      resourceDataManager,
    }: {
      persister: EntityPersistRunner
      resourceDataManager: ResourceDataManagerInterface
    },
  ): Promise<BatchFileResponse | null> {
    const items = await this.fetchSchedulingEntityByGroup(group.id, {
      resourceDataManager,
    })

    const attributesToUpdate: Record<
      SchedulingEntityResource,
      (keyof SchedulingGroupEntity)[]
    > = {
      [Resources.MOBILE_RUNSHEETS]: ['name', 'position'],
      [Resources.MOBILE_SCHEDULES]: ['customId'],
      [Resources.SITE_TASK_SCHEDULE_TIMINGS]: ['id'], // TODO: confirm with SEU-2060
    }
    const dataToUpdate = pick(group, attributesToUpdate[this.resource])

    const itemsToUpdate = items.filter((schedulingItem) => {
      const itemData = pick(schedulingItem, attributesToUpdate[this.resource])

      return !isEqual(itemData, dataToUpdate)
    })

    // Return if there are no items to update
    if (itemsToUpdate.length === 0) {
      return null
    }

    const operations: BatchFileOperation[] = itemsToUpdate.map(
      (schedulingItem) => ({
        action: BatchFileActions.UPDATE,
        resource: this.resource,
        data: { ...schedulingItem, ...dataToUpdate },
        lookup: schedulingItem.id.toString(),
      }),
    )

    if (operations.length === 0) {
      return null
    }

    const batchOperation = {
      onFailure: BatchFileOnFailureOptions.ABORT,
      operations,
    }

    return persister.executeBatchFileRequest(batchOperation)
  }

  getAccountKey() {
    switch (this.resource) {
      case Resources.MOBILE_RUNSHEETS:
        return 'zone'
      case Resources.MOBILE_SCHEDULES:
      case Resources.SITE_TASK_SCHEDULE_TIMINGS:
      default:
        return 'client'
    }
  }

  prepareGroupModel(group: SchedulingGroupEntity): Record<string, unknown> {
    const { id: schedulingGroup, ...groupData } = group

    return {
      ...groupData,
      [this.getGroupAttribute()]: schedulingGroup,
      ...(this.resource === Resources.MOBILE_SCHEDULES
        ? {
            beginServiceOn: new Date(groupData.beginServiceDate),
          }
        : {}),
    }
  }

  createEverydayGroupBatch(group: SchedulingGroupEntity): SchedulingGroupBatch {
    const model = this.prepareGroupModel(group)

    return model as SchedulingGroupBatch // to review
  }

  createCustomGroupBatch(
    group: SchedulingGroupEntity,
    schedulingItems: SchedulingEntity[],
    schedulingExceptions: SchedulingExceptionTypeEntity[],
  ): SchedulingGroupBatch {
    const model = this.prepareGroupModel(group)

    const dayOfWeekAttr = this.getDayOfWeekAttribute()
    const days: SchedulingGroupBatch['days'] = schedulingItems.reduce(
      (acc, schedulingItem) => {
        const key = schedulingItem[dayOfWeekAttr] as string

        return {
          ...acc,
          [key]: schedulingItem,
        }
      },
      {},
    )

    const holidays: SchedulingGroupBatch['holidays'] =
      schedulingExceptions.reduce(
        (acc, { exceptionType, perform, timeFrom, timeTo }) => ({
          ...acc,
          [this.schemaHelper.getHolidayKey(exceptionType)]: {
            doNotPerform: !perform,
            [rangeTimeKeys.START_TIME_KEY]: perform ? timeFrom : null,
            [rangeTimeKeys.END_TIME_KEY]: perform ? timeTo : null,
          },
        }),
        {},
      )

    return {
      ...model,
      ...(!isEmpty(days) ? { days } : {}),
      ...(!isEmpty(holidays) ? { holidays } : {}),
    } as SchedulingGroupBatch // to review
  }
}
