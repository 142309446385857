<template>
  <tt-attr name="category" :prevent-relation-preview="isImported" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetTypesCategoryAttr',
  inject: ['getItemHook'],
  computed: {
    isImported(): string {
      return this.getItemHook().getRawValue('isImported')
    },
  },
  created() {
    this.getItemHook().addAttribute('isImported')
  },
})
</script>
