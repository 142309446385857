<template>
  <tt-tile v-bind="props">
    <tt-attr slot="title" name="taskType.name" />
    <tt-attr slot="line2" name="name" />
  </tt-tile>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  inject: ['getItemHook'],
  created() {
    this.getItemHook().addAttribute('periodicSchedule.id')
  },
})
</script>
