import { ContextAccountHandler } from '@/tt-widget-factory/context/ContextAccountHandler'
import { Resources } from '@/tt-entity-design/src/types'

import {
  AssetTrackingSettings,
  FetchAssetTrackingSettingsParams,
} from '../types'

/**
 * Fetch Asset Tracking Settings
 * Fetch the account settings for Asset Tracking
 *
 * The settings are based on several factors determined by the portal configuration
 * and reflected on this endpoint
 */

// Key for the session storage
const ASSET_TRACKING_SETTINGS_KEY = 'AT_SETTINGS_'

/**
 * Function to get the storage key based on the account ID
 *
 * @param accountId number
 * @returns string
 */
function getStorageKey(accountId: number): string {
  return `${ASSET_TRACKING_SETTINGS_KEY}${accountId}`
}

/**
 * Function to get the asset tracking settings from the session storage
 *
 * @param accountId number
 * @returns AssetTrackingSettings | null
 */
export function getAssetTrackingSettingsFromStorage(
  accountId: number,
): AssetTrackingSettings | null {
  const storedSettings = sessionStorage.getItem(getStorageKey(accountId))

  if (storedSettings) {
    try {
      return JSON.parse(storedSettings) as AssetTrackingSettings
    } catch (e) {
      console.warn('Unnable to recover Asset Tracking Settings from cache: ', e)

      return null
    }
  }

  return null
}

/**
 * Function to set the asset tracking settings in the session storage
 *
 * @param accountId number
 * @param settings AssetTrackingSettings
 */
export function setAssetTrackingSettingsInStorage(
  accountId: number,
  settings: AssetTrackingSettings,
): void {
  sessionStorage.setItem(getStorageKey(accountId), JSON.stringify(settings))
}

/**
 * Function to fetch the asset tracking settings
 *
 * @param appContext AppContext
 * @returns Promise<AssetTrackingSettings>
 */
export async function fetchAssetTrackingSettings({
  accountId,
  appContext,
}: FetchAssetTrackingSettingsParams): Promise<AssetTrackingSettings> {
  /**
   * Get the account ID from the context if not provided
   */
  const account = accountId
    ? accountId
    : ContextAccountHandler(appContext).get()

  if (!account) {
    throw new Error('No account provided or set in the context.')
  }

  // Check if the settings are already in the sessionStorage
  let settings = getAssetTrackingSettingsFromStorage(account)

  if (!settings && account) {
    // If not, fetch from the API
    try {
      const response = await appContext.authModule
        .getApi()
        .httpClient.get(
          `${Resources.ASSETS}/accountsettings?accountId=${account}`,
        )

      settings = response.data as AssetTrackingSettings
      setAssetTrackingSettingsInStorage(account, settings)
    } catch (error) {
      console.error(
        'Error fetching asset tracking settings from the API',
        error,
      )
      throw new Error('Failed to fetch asset tracking settings.')
    }
  }

  return settings
}

/**
 * TODO: Refactor all these to use the resource name instead of these
 * "canCreateType" and "canCreateCategory".
 *
 * This is a temporary solution to allow the creation of categories and types
 * The fallback will be "true" for now because in general we don't want to mess with
 * the permissions, but for categories and types it can be "false" depending on
 * configurations, not permissions, that's why we have to use a custom endpoint for this.
 *
 * The asset tracking settings endpoint will be refactored to be at least a bit more generic.
 *
 * Ticket: SDAM-986
 */
export function canCreateEntity(
  resourceName: string,
  settings: AssetTrackingSettings,
): boolean {
  const { canCreateType, canCreateCategory } = settings || {}

  switch (resourceName) {
    case Resources.ASSET_TYPES:
      return canCreateType

    case Resources.ASSET_CATEGORIES:
      return canCreateCategory

    default:
      return true
  }
}
