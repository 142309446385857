<template>
  <WidgetPageStandAlone :widget="widget" />
</template>

<script lang="ts">
import Vue from 'vue'
import { SingleCounterWidgetModel } from '@/tt-widget-components/types'
import WidgetPageStandAlone from '../WidgetPageStandAlone.vue'
import { Resources } from '@/tt-entity-design/src/types'
export default Vue.extend({
  name: 'PhoneCallLogsCounter',
  components: { WidgetPageStandAlone },
  props: {
    title: { type: String },
  },
  computed: {
    widget(): SingleCounterWidgetModel {
      return {
        title: this.$props.title ?? '',
        is: 'SingleCounterWidget',
        dataSet: {
          resource: Resources.PHONE_CALL_LOGS,
          measure: {
            operation: 'SUM',
            alias: 'Sum',
            format: 'ROUND_0',
            attribute: 'duration',
          },
          contextFilters: {
            dateRangeAttribute: 'createdOn',
          },
        },
        allowDownload: {
          csv: true,
        },
        contextFilters: {
          defaults: [
            {
              value: 'THIS_MONTH',
              operator: 'EQUAL',
              attribute: 'dateRangeAttribute',
            },
          ],
        },
      }
    },
  },
})
</script>
