<template>
  <div v-if="showMap">
    <DispatchTasksFormLocationTile />

    <MapCoordinateSelector
      v-if="hasCoordinates"
      class="pt-2"
      hide-search-field
      prevent-click
      :default-coordinates="clientCoordinates"
    />
    <v-banner
      v-else
      class="my-2 pa-2 d-flex justify-center"
      dark
      outlined
      rounded
      color="warning"
      v-text="$t('common.address_not_geocoded')"
    />

    <!-- Change location button -->
    <DispatchTasksFormEditButton @click="openLocationTab" />
  </div>
  <div v-else>
    <v-card flat outlined>
      <v-skeleton-loader
        class="d-flex flex-column justify-center"
        boilerplate
        type="list-item-avatar-three-line"
        height="132px"
      />
    </v-card>
    <v-card class="mt-2 mb-11" flat outlined>
      <v-img :src="thumbnail" height="300px" contain eager />
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import { FormHookProvider } from '@/tt-widget-components'
import { ClientInterface } from '../types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Coordinates } from '@tracktik/tt-geo-proxy/lib/GeocodeSdk/types'
import { WidgetName } from '@/tt-widget-components/lib/names'
import DispatchTasksFormLocationTile from './DispatchTasksFormLocationTile.vue'
import MapCoordinateSelector from '@/tt-widget-components/components/map/MapCoordinateSelector.vue'
import DispatchTasksFormEditButton from './DispatchTasksFormEditButton.vue'
import DispatchTasksFormLocationPage from './DispatchTasksFormLocationPage.vue'

export default (
  Vue as VueConstructor<
    Vue &
      FormHookProvider &
      DispatchTaskFormServiceProvider & { namespace: string }
  >
).extend({
  name: 'DispatchTasksFormLocationSection',
  components: {
    DispatchTasksFormLocationTile,
    MapCoordinateSelector,
    DispatchTasksFormEditButton,
  },
  inject: ['formHook', 'service', 'namespace'],
  computed: {
    clientItems(): ClientInterface | null {
      return this.service.getClientItems()
    },
    isCustomLocation(): boolean {
      return this.service.getIsCustomLocation()
    },
    hasCoordinates(): boolean {
      return (
        !!this.clientCoordinates.latitude && !!this.clientCoordinates.longitude
      )
    },
    showMap(): boolean {
      return !!this.clientItems || this.service.getIsCustomLocation()
    },
    clientCoordinates(): Coordinates {
      if (this.isCustomLocation) {
        return {
          latitude: this.formHook().getPathValue('location.latitude'),
          longitude: this.formHook().getPathValue('location.longitude'),
        }
      } else {
        return {
          latitude: this.clientItems?.address?.latitude,
          longitude: this.clientItems?.address?.longitude,
        }
      }
    },
    thumbnail(): string {
      const config =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(
          WidgetName.MAP_WIDGET,
        )

      return config.config.thumbnail
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
    openLocationTab(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: DispatchTasksFormLocationPage,
        title: this.translationKey('select-custom-location'),
        props: {
          formHook: this.formHook,
          service: this.service,
          namespace: this.namespace,
        },
      })
    },
  },
})
</script>
