<template>
  <WidgetPageStandAlone :widget="widget">
    <template slot="context-toolbar-after">
      <div class="d-flex align-center flex-column bg-grey-lighten-4">
        <v-btn-toggle v-model="typeFilterValue" mandatory>
          <v-btn small text :value="smsLogsType.IN">
            {{ smsLogsType.IN }}
          </v-btn>
          <v-btn small text :value="smsLogsType.OUT">
            {{ smsLogsType.OUT }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </template>
  </WidgetPageStandAlone>
</template>

<script lang="ts">
import Vue from 'vue'
import { SingleCounterWidgetModel } from '@/tt-widget-components/types'
import WidgetPageStandAlone from '../WidgetPageStandAlone.vue'
import { Resources } from '@/tt-entity-design/src/types'

export enum SMSLogsType {
  IN = 'SMS.IN',
  OUT = 'SMS.OUT',
}

export default Vue.extend({
  name: 'SmsLogsCounter',
  components: { WidgetPageStandAlone },
  props: {
    title: { type: String, default: '' },
  },
  data() {
    return {
      typeFilterValue: SMSLogsType.OUT as SMSLogsType,
    }
  },
  computed: {
    smsLogsType() {
      return SMSLogsType
    },
    widget(): SingleCounterWidgetModel {
      return {
        title: this.$props.title,
        is: 'SingleCounterWidget',
        dataSet: {
          resource: Resources.SMS_LOGS,
          measure: {
            operation: 'SUM',
            alias: 'Sum',
            format: 'ROUND_0',
            attribute: 'segment',
          },
          filters: {
            filters: [
              {
                attribute: 'type',
                operator: 'EQUAL',
                value: this.typeFilterValue,
              },
            ],
          },
          contextFilters: {
            dateRangeAttribute: 'createdOn',
          },
        },
        allowDownload: {
          csv: true,
        },
        contextFilters: {
          defaults: [
            {
              value: 'THIS_MONTH',
              operator: 'EQUAL',
              attribute: 'dateRangeAttribute',
            },
          ],
        },
      }
    },
  },
})
</script>
