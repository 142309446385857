export enum Routes {
  HOME = '/',
  DASHBOARD = 'dashboard',
  ASSETS = 'assets',
  ASSET_SCAN = 'asset-scan',
  ASSET_TYPES = 'asset-types',
  ASSET_SERIAL_SEARCH = 'asset-serial-search',
  CATEGORIES = 'categories',
  MANUFACTURERS = 'manufacturers',
  SUPPLIERS = 'suppliers',
  REASONS = 'reasons',
  PERSONS = 'person',
  CUSTOM_ATTRIBUTES = 'custom-attributes',
  FIELDSET = 'fieldsets',
  BATCH_IMPORT = 'batch-import',
  NOTIFICATIONS = 'notifications',
  LATE_ASSETS = 'late-assets',
  CONTEXT_ACCOUNT_SELECTION = 'account-context',
  ASSET_RESERVATIONS = 'asset-reservations',
  IMPORT_WIZARD = 'import-wizard',
}
