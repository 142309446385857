<template>
  <div>
    <json-field name="id" />
    <json-field name="taskType" />
    <json-field name="site" />
    <json-field name="jobInstructions" />
    <json-field name="generalInstructions" />
    <json-field name="checkPointTour" />
    <json-field name="priceTier" />
    <json-field name="dispatchSla" />
    <json-field name="generalDispatchSla" />
    <json-field name="dispatchableAssets" />
    <json-field name="schedulableAssets" />
    <json-field name="siteTaskAssets" />
    <span class="text-body-1 grey--text text--darken-3">
      {{ $t('vendors.tabs.assign-job-type-form.assign-vendor') }}
    </span>
    <v-divider class="mt-2 mb-4" />
    <json-field
      :disabled="!selectedTaskTypeId || !isDispatchable"
      :hide-details="true"
      name="vendor"
    />
    <span
      v-if="!isDispatchable"
      class="mt-2 text-body-2 grey--text text--darken-1"
    >
      {{ $t('vendors.tabs.assign-job-type-form.dispatchable-warning') }}
    </span>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { getTaskType } from './helpers/task-site-instructions-api-helper'
import { TaskType } from './types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'TaskSiteInstructionsForm',
  inject: ['formHook'],
  data() {
    return {
      selectedTaskType: null as TaskType,
    }
  },
  computed: {
    selectedTaskTypeId(): number | null {
      return this.formHook().getPathValue('taskType') || null
    },
    isDispatchable(): boolean {
      return this.selectedTaskType?.dispatchable ?? true
    },
  },
  watch: {
    async selectedTaskTypeId(taskTypeId) {
      this.selectedTaskType = await getTaskType(this.$appContext, taskTypeId, [
        'dispatchable',
      ])
      this.formHook().setObjectValue('vendor', null)
    },
  },
})
</script>
