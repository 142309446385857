import { AppContext } from '@/tt-app-context'
import { EntityCreateIntentInterface } from '@/tt-widget-entity-flow'
import { ValueOf } from '@/helpers/types/ValueOf'
import { ColumnDefinition } from '@/tt-widget-components'

/**
 * Create Menu Items
 */
export type AssetCreateMenuItem = {
  resource?: string
  route?: string
  canShow?: boolean
}

/**
 * Asset Events
 */
export enum AssetEditorEvents {
  PERSON_CREATE = 'assets.person.editor.create',
}

export type AssetEditorEventPayload = {
  [AssetEditorEvents.PERSON_CREATE]: EntityCreateIntentInterface
}

/**
 * Asset Serial Search
 * It'll be used to look for an asset searching it's serial number.
 * The search shall look up among all the sites of that said client but the
 * results will be filtered by the backend for safety.
 *
 * The account.id can also be null if the user doesn't have access to that site
 * The (asset) id can also be null if the asset is inactive
 */
export type AssetSerialSearchIdType = number | null
export type AssetSerialSearchResultAccount = {
  id: AssetSerialSearchIdType
  name: string
  type: AssetSerialSearchIdType
  typeName: string | null
}
export type AssetSerialSearchResultType = {
  id: AssetSerialSearchIdType
  icon: string
  name: string
  color: string
}

export type AssetSerialSearchResult = {
  name: string | null
  id: AssetSerialSearchIdType
  type: AssetSerialSearchResultType
  status: AssetStatusCode
  serialNumber: string
  message: string
  account: AssetSerialSearchResultAccount
}

/**
 * Asset Status Color Schemas
 */
export enum assetStatusEnums {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
}

export type AssetStatusCode = ValueOf<typeof assetStatusEnums>

export type AssetStatusColor = {
  backgroundColor: string
  textColor: string
}

/**
 * Account types enums
 */
export enum accountTypeEnums {
  CLIENT = 'site',
  ZONE = 'zone',
}

/**
 * Asset Types and Categories columns and filters blacklist
 */
export type FiltersBlackList = {
  [key: string]: string[]
}

export type ColumnBlackList = {
  [key: string]: ColumnDefinition[]
}

/**
 * Asset Types and Categories import wizard
 */
export type AssetCategoryImportItem = {
  id: string
  name: string
  icon?: string | null
  color?: string | null
  description?: string | null
}

export type AssetTypeImportItem = {
  id: string
  name: string
  icon?: string | null
  color?: string | null
  category: AssetCategoryImportItem
}

/**
 * Asset Tracking Settings
 */
export interface FetchAssetTrackingSettingsParams {
  accountId?: number | undefined
  appContext?: AppContext
}

export type AssetTrackingSettings = {
  canCreateType: boolean
  canCreateCategory: boolean
  [key: string]: any
}
export type AssetTrackingSettingsProvider = {
  assetTrackingSettings: AssetTrackingSettings
}
