<template>
  <DateTimeField
    v-model="model"
    v-bind="{ ...$attrs, ...options, disabled: isReadOnly }"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { convertPrefToMoment } from '@/helpers/formats/dates/utils'
import { FormHookProvider } from '@/tt-widget-components'

/**
 * DateTimeField wrapped with provided timezone, locale, and format
 */
export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'DateTimeFieldWrapper',
  inject: {
    formHook: { default: undefined },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit('input', value)
      },
    },
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || false
    },
    options(): any {
      const userPref = this.$auth.getUserPreferences()

      const userDatetimeFormat = convertPrefToMoment(userPref.dateTimeFormat)
      const timeZone = userPref.timeZone
      const locale = this.$i18n.locale

      return { userDatetimeFormat, locale, timeZone }
    },
  },
})
</script>
