import isEmpty from 'lodash/isEmpty'
import { Resolvable } from './types'
import VueI18n from 'vue-i18n/types'

export const resolve = <T>(
  resolvable: Resolvable<T>,
  defaultValue?: T,
  args: any[] = [],
): T => {
  const resolved =
    resolvable instanceof Function ? resolvable(...args) : resolvable

  return resolved ?? defaultValue
}

/**
 *
 * @param i18Messages - use this.$i18n.messages as input to this method to check if translations have been loaded
 * @returns whether or not translations have been loaded
 */
export const isTranslationLoaded = (
  i18Messages: VueI18n.LocaleMessages,
): boolean => {
  return !isEmpty(i18Messages)
}
